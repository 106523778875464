import request from '@/service/lib/request';


//用户分页数据
export async function getUserList(params) {
  return request({
    url: '/appuser/users',
    method: 'get',
    params,
  });
}
//用户核销酒水 提交
export async function UserCheck(params) {
  return request({
    url: '/appuser/checkuser',
    method: 'post',
    data: params,
  });
}