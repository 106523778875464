

<template>
  <div class="main-conent main-conent-minheight" v-loading="loading">
    <el-card class="SearchBox">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="用户昵称">
          <el-input placeholder="请输入用户昵称" v-model="searchForm.appUserName" clearable />
        </el-form-item>
        <el-form-item label="用户编号">
          <el-input-number placeholder="请输入用户编号" v-model="searchForm.appUserId" clearable />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="dataBinding">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card shadow="never" class="border-none">
      <el-table :data="tableData" stripe fit>
        <el-table-column prop="id" label="编号" width="50" />
        <el-table-column prop="id" label="" width="80">
          <template slot-scope="scope">
            <el-avatar size="large" :src="scope.row.avatar"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="nickName" label="用户昵称" width="200" />
        <el-table-column prop="strRegTime" label="注册日期" width="150" />
        <el-table-column label="持有酒水">
          <template slot-scope="scope">
            <div class="content-rowspan">
              <div v-for="(prod,index) in scope.row.products">
                <span class="content-rowspan">
                  {{prod.name}}：{{prod.count}}{{prod.unit}}
                </span>

              </div>
            </div>
            <!-- <el-table-column v-for="prod in scope.row.products" v-bind="prod.id" :prop="prod.count"
              label="JJJJJ" /> -->
          </template>

        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="danger" @click="openCheckDialog(scope.row)">核销酒水</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="margin-t16 text-right">
        <el-pagination background layout="prev, pager,next" :total="totalCount" :current-page="searchForm.pageIndex"
          :page-size="searchForm.pageSize" @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </el-card>
    <el-dialog title="核销酒水" :visible.sync="dialogFormVisible">
      <el-form :model="checkDialogData" label-position="left">
        <el-form-item label="用户编号" :label-width="formLabelWidth">
          <span>{{checkDialogData.appUserId}}</span>
        </el-form-item>
        <el-form-item label="用户昵称" :label-width="formLabelWidth">
          <span>{{checkDialogData.appUserName}}</span>
        </el-form-item>
        <el-form-item v-for="prod in checkDialogData.products" :key="prod.id" :label="prod.name" :label-width="formLabelWidth">
          <el-col :span="14">
            <el-input-number v-model="prod.count" :min="0" :max="prod.amount"></el-input-number>
          </el-col>
          <el-col :span="10">
            <span style="color:#67c23a;">
              最大可核销数量：{{prod.amount}}
            </span>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleCheckUser()">确 认 核 销</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getUserList, UserCheck } from '@/service/appuser.js';
import { activities, scenes } from '@/service/dropdown.js';
export default {
  data() {
    return {
      loading: false,
      dialogFormVisible: false,
      formLabelWidth: '150px',
      totalCount: 0,
      searchForm: {
        pageIndex: 1,
        pageSize: 20,
        appUserName: '',
        appUserId: 0,
      },
      tableData: [],
      checkDialogData: {
        appUserId: 0,
        products: [
          {

          }
        ]
      }
    };
  },
  async created() {
    await this.dataBinding();
  },

  methods: {
    //加载页面数据
    async dataBinding() {
      return new Promise((resolve, reject) => {
        this.loading = true;
        getUserList(this.searchForm).then(res => {
          this.totalCount = res.data.totalCount;
          this.tableData = res.data.data;
          this.loading = false;
          resolve();
        });
      })
    },
    //页码切换
    handleCurrentChange(val) {
      if (this.searchForm.pageIndex != val) {
        this.searchForm.pageIndex = val;
        this.dataBinding();
      }
    },
    //打开后台核销窗口
    openCheckDialog(data) {
      console.log(data);
      this.checkDialogData = null;
      //赋值基础属性
      this.checkDialogData = {
        appUserId: data.id,
        appUserName: data.nickName,
        products: [],
      };
      //赋值酒水属性
      data.products.forEach(element => {
        this.checkDialogData.products.push({
          amount: element.count,
          count: 0,
          name: element.name,
          unit: element.unit,
          id: element.id,
        });
      });
      this.dialogFormVisible = true;
    },
    //用户酒水核销提交
    handleCheckUser() {
      UserCheck(this.checkDialogData).then(res => {
        if (res.code === 0) {
          this.$message.success('核销成功！！');
          this.dataBinding();
        } else {
          this.$message.error('核销失败，请刷新页面后重试');
        }
        this.dialogFormVisible = false;
      })
    }
  }
};
</script>
<style scoped>
.Middle {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.SearchBox {
  margin-bottom: 30px;
}
</style>